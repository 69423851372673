import Head from 'next/head'
import { useEffect } from 'react'
import { logWarning } from 'shared-code/log'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'

function isRampRuntime(item: unknown): item is PlaywireRampRuntimeProps {
  if (typeof item !== 'object' || !item) {
    logWarning('Ramp object is not defined')
    return false
  }

  const result = 'destroyUnits' in item && 'addUnits' in item && 'displayUnits' in item

  if (!result) {
    logWarning('Ramp functions are undefined')
  }
  return result
}

interface PlaywireRampScriptProps {
  publisherId: string
  websiteId: string
}

const PlaywireRampScript: React.VFC<PlaywireRampScriptProps> = ({ publisherId, websiteId }) => {
  const { setInst } = PlaywireContext.useContainer()

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.rampIsInitialized === true) {
        clearInterval(interval)
        setInst(isRampRuntime(window.ramp) ? window.ramp : null)
      }
    }, 1)
    return () => clearInterval(interval)
  }, [setInst])

  return (
    <Head>
      <script
        key="playwire-code"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            var ramp = { 
                passiveMode: true,
                que: [],
                onReady: function() { window.rampIsInitialized = true }
            }; 
      `,
        }}
      />
      <script
        key="playwire-runtime"
        type="text/javascript"
        async
        src={`//cdn.intergient.com/${publisherId}/${websiteId}/ramp.js`}
      />
    </Head>
  )
}

export default PlaywireRampScript
