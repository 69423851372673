import { useRouter } from 'next/router'
import { DealBarContext } from 'shared-components/contexts/DealBarContext'
import UnderNavBlock from 'shared-components/layout/UnderNavBlock'
import { UIVariantContext } from 'shared-components/contexts/UIVariantContext'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './Layout.module.css'
import PopupWrapper from 'shared-components/layout/PopupWrapper'
import PageTransition from 'shared-components/layout/PageTransition'
import Header from 'components/Header'
import Footer from 'shared-components/layout/Footer'
import { ThemeContext } from 'shared-components/contexts/ThemeContext'
import { useCallback, useEffect } from 'react'
import { NotificationsContext } from 'shared-components/contexts/NotificationsContext'
import dynamic from 'next/dynamic'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import { publicConfig } from 'config'
import Thirdparty from 'components/service/Thirdparty'
import Logo from 'shared-svg/AuthorityMedia.svg'
import { useOutboundLinkListener } from 'shared-components/hooks/use-outbound-link-listener'
import { getds } from 'ds'
import { DSGetDealBar } from 'types.sg.ds'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'

const AdminPanel = dynamic(() => import('shared-components/layout/AdminPanel'), { ssr: false })
const NotificationsLayer = dynamic(() => import('shared-components/layout/NotificationsLayer'))

const Layout: React.FC = ({ children }) => {
  const { isPreview } = useRouter()
  const { queue } = NotificationsContext.useContainer()
  const { ready, theme } = ThemeContext.useContainer()
  const { showAdminPanel } = CommonDataContext.useContainer()
  useEffect(() => {
    if (!ready) {
      return
    }

    document.body.classList[theme === 'dark' ? 'add' : 'remove'](styleUtils.darkTheme)
  }, [ready, theme])
  const getDealbar = useCallback(async (...args: Parameters<DSGetDealBar>) => {
    const dataSource = await getds()
    return dataSource.getDealBar(...args)
  }, [])
  return (
    <PlaywireContext.Provider
      initialState={{
        inst: null,
        enabled: !publicConfig('adsDisabled'),
        adClassList: publicConfig('adClassList'),
      }}
    >
      <UIVariantContext.Provider
        initialState={{ postPreviewScore: 'ring', buttonStyle: 'outline' }}
      >
        <DealBarContext.Provider initialState={{ getDealbar, dealbar: null }}>
          {showAdminPanel ? <AdminPanel editPostTpl={publicConfig('postEditLink')} /> : null}
          <Header />
          <UnderNavBlock />
          <main className={styles.main} ref={useOutboundLinkListener()}>
            {children}
          </main>
          <Footer logo={Logo} />
          <PopupWrapper />
          <PageTransition />
          {queue.length ? <NotificationsLayer /> : null}
          {!isPreview ? <Thirdparty /> : null}
        </DealBarContext.Provider>
      </UIVariantContext.Provider>
    </PlaywireContext.Provider>
  )
}

export default Layout
