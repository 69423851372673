import { createContainer } from 'shared-components/unstated'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { DSGetterHandler, DSDealBar, DSAdvancedPlacementQuery } from 'shared-definitions/types'
import { CommonDataContext } from './CommonDataContext'

interface DealBarValueProps {
  hiddenOnMobile?: boolean
  getDealbar: DSGetterHandler<DSDealBar | null, [id: string, query: DSAdvancedPlacementQuery]>
  dealbar: DSDealBar | null
}

interface DealBarContextProps extends Required<DealBarValueProps> {
  setState: Dispatch<SetStateAction<Required<Omit<DealBarValueProps, 'getDealbar'>>>>
}

export const DealBarContext = createContainer<DealBarContextProps, DealBarValueProps>(initial => {
  if (initial === undefined) {
    throw new Error('DealbarContext initial is undefined')
  }

  const dealbar = useDealbar(initial.getDealbar)
  const [state, setState] = useState<Required<Omit<DealBarValueProps, 'getDealbar'>>>(() => ({
    hiddenOnMobile: initial.hiddenOnMobile === undefined ? false : initial.hiddenOnMobile,
    dealbar,
  }))

  return {
    ...state,
    setState,
    dealbar,
    getDealbar: initial.getDealbar,
  }
})

function useDealbar(getter: DealBarValueProps['getDealbar']): DSDealBar | null {
  const postId = undefined

  const {
    common: { dealBar },
  } = CommonDataContext.useContainer()

  const [dealbarData, setDealbarData] = useState<DSDealBar | null>(dealBar ? dealBar.default : null)
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    if (!dealBar) {
      return
    }

    const key = `dblink_${postId ?? ''}${dealBar.id}`
    const saved = sessionStorage.getItem(key)
    if (saved) {
      const newLink = JSON.parse(saved) as DSDealBar
      if (newLink) {
        setDealbarData(newLink)
      }
      setFetched(true)
      return
    }

    void (async () => {
      const { data } = await getter(dealBar.id, {
        type: dealBar.type,
        postId: postId ?? undefined,
      })
      sessionStorage.setItem(key, JSON.stringify(data))
      if (data) {
        setDealbarData(data)
      }
      setFetched(true)
    })()
  }, [dealBar, getter, postId])

  return fetched ? dealbarData : null
}
