import { makeConfig } from 'shared-code/util'

export const publicConfig = makeConfig({
  adClassList: ['ad-banner'],
  postEditLink: process.env.NEXT_PUBLIC_POST_EDIT_LINK?.toString(),
  oneSignalTagLimit: 2,
  oneSignalAppId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID?.toString(),
  gtmDesktopId: process.env.NEXT_PUBLIC_GTM_DESKTOP?.toString(),
  pageRevalidateDelay: 20,
  useTrailingSlash: process.env.NEXT_PUBLIC_USE_TRAILING_SLASH?.toString() === 'true',
  postsPerPage: 10,
  searchMinLength: 1,
  appUrl: process.env.NEXT_PUBLIC_APP_URL?.toString(),
  dataSourceType: process.env.NEXT_PUBLIC_DATA_SOURCE_TYPE?.toString(),
  wpApiConfig: {
    baseUrl: process.env.NEXT_PUBLIC_WP_BASE_URL?.toString(),
  },
  affilimateToken: process.env.NEXT_PUBLIC_AFFILIMATE_TOKEN?.toString(),
  gtmGtagId: process.env.NEXT_PUBLIC_GTAG_ID?.toString(),
  playwirePublisher: process.env.NEXT_PUBLIC_PLAYWIRE_PUBLISHER?.toString(),
  playwireWebsite: process.env.NEXT_PUBLIC_PLAYWIRE_WEBSITE?.toString(),
  adsDisabled: false,
  onesignalLoadDelay: 5000,
  adBlockerScript: 'https://btloader.com/tag?o=5150306120761344&upapi=true',
  sailThruCustomerId: process.env.NEXT_PUBLIC_SAILTHRU_CUSTOMER_ID?.toString(),
  inlineAdClass: 'in-article',
  inlineAdVideoClass: 'in-article-video',
  relatedAdClass: null,
  sidebarAdVideoClass: 'sidebar-video',
  funnelCustomerId: process.env.NEXT_PUBLIC_FUNNEL_ID?.toString(),
  algoliaAppId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID?.toString(),
  algoliaKeyId: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY?.toString(),
  algoliaSearchIndex: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX?.toString(),
  googleOptimizeId: process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE?.toString(),
  contactsFormId: 'contacts-form',
} as const)

export const privateConfig = makeConfig({
  serverCsrfToken: process.env.SERVER_CSRF_TOKEN?.toString(),
  previewToken: process.env.PREVIEW_SECRET_TOKEN?.toString(),
  useProductionRobotsTxt: process.env.USE_PRODUCTION_ROBOTS_TXT?.toString() === 'true',
  sailThruKey: process.env.SAILTHRU_API_KEY?.toString(),
  sailThruSecret: process.env.SAILTHRU_API_SECRET?.toString(),
} as const)
