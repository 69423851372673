import clsx from 'clsx'
import Linked, { LinkedProps } from 'shared-components/service/Linked'
import styleContentButton from 'shared-components/styles/content-button.module.css'

import { BasicStyledComponent } from 'shared-definitions/types'

interface OfferButtonLinkProps extends BasicStyledComponent, LinkedProps {
  innerClassName?: string
}

const OfferButtonLink: React.FC<OfferButtonLinkProps> = ({
  className,
  children,
  innerClassName,
  ...props
}) => (
  <Linked className={clsx(styleContentButton.button, className)} {...props}>
    <div className={clsx(styleContentButton.inner, innerClassName)}>
      <span className={styleContentButton.innerText}>{children}</span>
    </div>
  </Linked>
)

export default OfferButtonLink
