import styles from './Footer.module.css'
import { useInView } from 'react-intersection-observer'
import dynamic from 'next/dynamic'
import { BasicStyledComponent } from 'shared-definitions/types'

export interface FooterProps {
  logo: React.VFC<BasicStyledComponent>
}

const FooterContent = dynamic(() => import('shared-components/layout/FooterContent'), {
  ssr: false,
})

const Footer: React.VFC<FooterProps> = props => {
  const { ref, inView } = useInView({ triggerOnce: true })

  return (
    <footer ref={ref} className={styles.container}>
      {inView ? <FooterContent {...props} /> : null}
    </footer>
  )
}

export default Footer
