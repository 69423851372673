import clsx from 'clsx'
import { BasicStyledComponent, ProductBarProps } from 'shared-definitions/types'
import OfferButtonLink from 'shared-components/buttons/OfferButtonLink'
import Score from 'shared-components/ui/Score'
import styles from './BestBuyPanel.module.css'
import Cart from 'shared-svg/Cart.svg'

interface BestBuyPanelProps extends BasicStyledComponent {
  product: ProductBarProps
}

const BestBuyPanel: React.VFC<BestBuyPanelProps> = ({ className, product }) => (
  <div className={clsx(styles.container, className)}>
    <div className={styles.name}>
      <span className={styles.nameText}>{product.name}</span>
    </div>
    <OfferButtonLink {...product.button.pLink} className={styles.button}>
      <span className={styles.buttonText}>{product.button.label}</span>
      <Cart className={styles.buttonIcon} />
    </OfferButtonLink>
    {product.score ? <Score score={product.score} className={styles.scoreLine} /> : null}
    {product.manufacturer ? (
      <div className={styles.manufacturer}>{product.manufacturer}</div>
    ) : null}
  </div>
)

export default BestBuyPanel
